.host {
  position: fixed;
  right: 0;
  bottom: 0;
  z-index: 9999;
  border-radius: 8px;
  background: linear-gradient(to top, #c471f5 0%, #fa71cd 100%);
  padding: 8px;
  color: #fff;
  font-size: 14px;
}

.select {
  border-radius: 4px;
}

.expanded {
  width: 100%;
}

.row {
  display: flex;
  grid-gap: 8px;
}

.list {
  margin: 8px;
  max-height: 300px;
  overflow: auto;
}

.item:not(:last-child) {
  margin-bottom: 8px;
}
