@use '../../../../styles/theme';
@use '../../../../styles/bootstrap';
@use '../../../../styles/utils';

.backdrop {
  backdrop-filter: blur(6px);
  background-color: fade-out(theme.$color-black, 0.7);

  &:global(.show) {
    opacity: 1;
  }
}

@mixin modal-margin-vertical($value) {
  margin: $value auto;
  min-height: bootstrap.subtract(100%, 2 * $value);
}

.modal {
  $padding-horizontal: 88px;
  width: 360px;
  max-width: bootstrap.subtract(100%, 2 * $padding-horizontal);
  @include modal-margin-vertical(60px);
  display: flex;
  align-items: center;
  box-sizing: content-box;
  padding: 0 $padding-horizontal;

  :global(.modal-content) {
    padding: 24px;
    @include utils.safe-area-aware-padding-bottom(24px);
    border: none;
    border-radius: 24px;
    background-color: theme.$local-bg;
  }
}

@include bootstrap.media-breakpoint-down(md) {
  .modal {
    @include modal-margin-vertical(60px);
  }
}

@include bootstrap.media-breakpoint-down(sm) {
  .modal {
    :global(.modal-content) {
      padding: 24px;
      @include utils.safe-area-aware-padding-bottom(24px);
    }
  }
}
