@use '../../../styles/theme';

#nprogress {
  .bar {
    background-color: theme.$light-blue;
  }

  .peg {
    box-shadow:
      0 0 10px theme.$light-blue,
      0 0 5px theme.$light-blue;
  }
}
