.container {
  text-decoration: underline;
  &,
  &:visited,
  &:hover {
    color: inherit;
  }
  &:hover {
    text-decoration: none;
  }
}
