@use '../../../../styles/theme';
@use '../../../../styles/bootstrap';
@use '../../../../styles/utils';

.modal {
  :global(.modal-content) {
    align-items: center;
    background-color: theme.$main-color;
  }
}

.imageContainer {
  margin-bottom: 24px;
}

.title {
  margin: 0 0 8px;
  color: theme.$local-bg;
  font-weight: 600;
  font-size: 20px;
  line-height: 130%;
  font-family: theme.$font-family-secondary;
  letter-spacing: 0.01em;
  text-align: center;
}

.description {
  color: theme.$local-bg-transparent;
  font-weight: normal;
  font-size: 16px;
  line-height: 150%;
  font-family: bootstrap.$font-family-base;
  text-align: center;

  &:not(:last-child) {
    margin-bottom: 32px;
  }
}

@include bootstrap.media-breakpoint-down(sm) {
  .backdrop {
    @include utils.cover-area-under-modal(theme.$color-black-transparent);
  }

  .container {
    display: flex;
    justify-content: flex-end;

    &:global(.fade) {
      transition: transform 0.3s ease-in-out;

      &:global(.show) {
        transform: none;
      }

      &:not(:global(.show)) {
        transform: translateY(100%);
        opacity: 1;
      }

      .modal {
        transform: none;
        transition: none;
      }
    }
  }

  .modal {
    align-items: flex-end;
    margin: 0;
    padding: 0;
    width: 100%;
    max-width: none;
    height: 100%;
    min-height: 0;

    :global(.modal-content) {
      border: 0 !important;
      border-radius: 0 !important;
    }
  }
}
