@use './theme';

@import './bootstrap';

@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/helpers';
@import '~bootstrap/scss/type';
@import '~bootstrap/scss/spinners';
@import '~bootstrap/scss/containers';
@import '~bootstrap/scss/buttons';
@import '~bootstrap/scss/modal';
@import '~bootstrap/scss/forms';
@import '~bootstrap/scss/dropdown';
@import '~bootstrap/scss/transitions';
@import '~bootstrap/scss/carousel';
@import '~bootstrap/scss/accordion';

@import './bootstrap-global';

html {
  min-height: 100%;
}

body,
#__next {
  flex: 1;
}

html,
body,
#__next {
  display: flex;
  flex-direction: column;
  margin: 0;
  padding: 0;
}

html,
body {
  background-color: var(--local-bg);
}

:root {
  color: theme.$light-blue;
  color-scheme: only light;
  font-size: $font-size-base;
  line-height: $line-height-base;
  font-family: $font-family-base;

  --font-family-base: 'Inter', sans-serif;
  --font-family-secondary: 'Lora', serif;

  --main-color: #a6a1c5;
  --color-white: #ffffff;
  --color-black: #000000;
  --color-black-transparent: rgba(0, 0, 0, 0.4);
  --local-bg-transparent: rgba(24, 25, 38, 0.8);
  --color-foreground-lighter-4: #c8c4bb;
  --color-pastel: #817b6f;
  --color-mint-green: #7fd1ae;
  --color-red: #e94249;
  --color-pink: #a84c7c;
  --color-gray: #737275;
  --local-bg: #181926;
  --dark-purple: #848099;
  --deep-purple: rgba(132, 128, 153, 0.2);
  --light-purple: #b2a8ee;
  --local-purple: #b2a4fa;
  --light-blue: #eaf1f4;
  --cream: #fcf8ef;
  --light-pink: #e4acf2;
  --local-pink: #c29fcb;
}

a {
  text-decoration: none;

  &,
  &:visited,
  &:hover {
    color: inherit;
  }
}

a,
button,
input,
select,
textarea {
  touch-action: manipulation;
}

a,
button,
label {
  cursor: pointer;
  user-select: none;
}

button {
  border: none;
  background: none;
  padding: 0;
  overflow: visible;
  color: inherit;
  text-align: left;

  &::-moz-focus-inner {
    border: none;
    padding: 0;
  }
}

h1,
h2,
h3,
h4,
h5,
h6,
p,
figure,
ul,
ol {
  margin: 0;
}

ul,
ol {
  padding: 0;
  list-style: none;
}

@for $i from 1 through 6 {
  $zIndexBackdrop: #{1000 + (5 * $i)};
  $zIndexContent: #{1000 + (5 * $i) + 2};
  .modal-backdrop:nth-of-type(#{$i}) {
    z-index: $zIndexBackdrop;
  }
  div[role='dialog'][aria-modal='true']:nth-of-type(#{$i}) {
    z-index: $zIndexContent;
  }
}

@include media-breakpoint-down(sm) {
  #__next {
    transition: transform 0.3s ease-in-out;
  }
  body[data-animated-modals-open]:not([data-animated-modals-open='0']) #__next {
    transform: translateX(-25%);
  }
}
