$font-family-secondary: 'Lora', serif;

$color-black: #000000;
$color-black-transparent: rgba(0, 0, 0, 0.4);
$color-white: #ffffff;
$main-color: #a6a1c5;
$light-blue: #eaf1f4;
$dark-purple: #848099;
$cream: #fcf8ef;
$local-bg: #181926;
$local-bg-gradient: linear-gradient(
  180deg,
  rgba(24, 25, 38, 0) 0%,
  #181926 100%
);
$cream-gradient: linear-gradient(
  180deg,
  rgba(252, 248, 239, 0) 0%,
  #fcf8ef 100%
);
$local-bg-transparent: rgba(24, 25, 38, 0.8);
$cream-transparent: rgba(252, 248, 239, 0.8);
$deep-purple: rgba(132, 128, 153, 0.2);
$color-red: #e94249;
$color-mint-green: #7fd1ae;
