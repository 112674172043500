@use '../../../styles/bootstrap';

.container {
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  border-radius: 44px;
  padding: 9px 24px;
  width: 327px;
  height: 60px;
  font-size: 16px;
  text-align: center;
}

.containerPrimary {
  font-weight: 700;

  &,
  &:hover,
  &:active,
  &:focus,
  &:active:focus,
  &:visited {
    background-color: var(--cream);
    color: var(--local-bg);
  }

  &:disabled {
    opacity: 1;
    background-color: var(--color-gray);
  }
}

.containerSecondary {
  &,
  &:hover,
  &:active,
  &:focus,
  &:active:focus,
  &:visited {
    background-color: transparent;
    color: var(--dark-purple);
  }

  &:disabled {
    opacity: 1;
    background-color: var(--color-gray);
  }
}

.containerTertiary {
  &,
  &:hover,
  &:active,
  &:focus,
  &:active:focus,
  &:visited {
    background-color: #a6a1c5;
    color: var(--color-white);
    font-weight: 700;
  }

  &:disabled {
    opacity: 1;
    background-color: rgba(132, 128, 153, 0.2);
    color: rgba(132, 128, 153, 0.2);
  }
}

.containerOptionButton {
  &,
  &:hover,
  &:active,
  &:focus,
  &:active:focus,
  &:visited {
    border: 1px solid var(--deep-purple);
    border-radius: 64px;
    background-color: transparent;
    color: var(--main-color);
  }
}

.containerOptionButtonSelected {
  &,
  &:hover,
  &:active,
  &:focus,
  &:active:focus,
  &:visited {
    border-radius: 64px;
    background-color: var(--main-color);
    color: var(--local-bg);
  }
}

.containerOptionButtonWithIcon {
  svg {
    margin-bottom: 16px;
  }
}

.containerOptionButtonWithIcon,
.containerOptionButtonWithIconSelected {
  &,
  &:hover,
  &:active,
  &:focus,
  &:active:focus,
  &:visited {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    box-shadow: none;
    border-radius: 5px;
    background-color: transparent;
    padding: 0;
    width: 120px;
    height: 120px;
    color: var(--main-color);
    white-space: nowrap;
  }
}

.containerOptionButtonWithIconSelected {
  svg {
    margin-bottom: 8px;
  }
  p {
    border-radius: 40px;
    background-color: var(--main-color);
    padding: 8px 16px;
    color: var(--local-bg);
    font-weight: 700;
    line-height: 150%;
  }
}

.containerOptionButtonNoBorder,
.containerOptionButtonNoBorderSelected {
  &,
  &:hover,
  &:active,
  &:focus,
  &:active:focus,
  &:visited {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 9px 24px;
    width: 327px;
    height: 60px;
    grid-gap: 10px;
    backdrop-filter: blur(4px);
    border-radius: 44px;
    background: var(--transparent-dark-blue, rgba(24, 25, 38, 0.8));
  }
}

.containerOptionButtonNoBorder {
  p {
    color: var(--light-blue);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
  }
}

.containerOptionButtonNoBorderSelected {
  background-color: var(--main-color);
  p {
    color: var(--local-bg);
  }
}

.containerOptionButtonWithImage,
.containerOptionButtonWithImageSelected,
.containerOptionButtonWithImageNotSelected {
  &,
  &:hover,
  &:active,
  &:focus,
  &:active:focus,
  &:visited {
    display: flex;
    flex: 1 0 0;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    backdrop-filter: blur(4px);
    border: 1px solid var(--main-color);
    border-radius: 16px;
    background: var(--Transparent-Dark-blue, rgba(24, 25, 38, 0.8));
    padding: 0;
    width: 156px;
    height: 232px;
    overflow: hidden;
  }
  svg {
    margin-top: 12px;
  }
  p {
    background: var(--main-color);
    padding: 12px;
    width: 100%;
    color: var(--local-bg);
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
  }
}

.containerOptionButtonWithImage {
  p {
    background: var(--main-color);
  }
}

.containerOptionButtonWithImageSelected {
  border: 1px solid var(--cream);
  p {
    background: var(--cream);
  }
}

.containerOptionButtonWithImageNotSelected {
  border: 1px solid var(--color-gray);
  p {
    background: var(--color-gray);
  }
  svg {
    opacity: 0.5;
  }
}

.containerOptionButtonSquared,
.containerOptionButtonSquareSelected {
  &,
  &:hover,
  &:active,
  &:focus,
  &:active:focus,
  &:visited {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 12px 16px;
    width: 157px;
    min-height: 92px;
    grid-gap: 8px;
    backdrop-filter: blur(4px);
    border-radius: 16px;
    background: var(--Transparent-Dark-purple, rgba(132, 128, 153, 0.2));
  }

  svg {
    width: 40px;
    height: 40px;
  }
  p {
    color: var(--light-blue);
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
  }
}

.containerOptionButtonSquareSelected {
  border: 1px solid var(--cream);
}

.containerOptionButtonLeftImage,
.containerOptionButtonLeftImageNotSelected {
  &,
  &:hover,
  &:active,
  &:focus,
  &:active:focus,
  &:visited {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    padding: 0 16px;
    width: 100%;
    height: 56px;
    grid-gap: 16px;
    border-radius: 16px;
    background: var(--cream);
  }

  svg {
    width: 40px;
    height: 40px;
  }
  p {
    color: var(--local-bg);
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
  }
}

.containerOptionButtonLeftImage {
  background: var(--cream);
}

.containerOptionButtonLeftImageNotSelected {
  background: var(--color-gray);
}

.sm {
  width: auto;
  height: 24px;
}

.lg {
  height: 72px;
}
